"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelValidator = void 0;
const snake_case_validator_1 = require("./snake-case-validator");
class LabelValidator {
    constructor() {
        this.snakeCaseValidator = new snake_case_validator_1.SnakeCaseValidator();
    }
    validate(trackingData) {
        const label = trackingData.label;
        if (typeof label === 'undefined')
            return;
        this.snakeCaseValidator.validate(label);
    }
}
exports.LabelValidator = LabelValidator;
