"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyValidator = void 0;
const snake_case_validator_1 = require("./snake-case-validator");
class PropertyValidator {
    constructor() {
        this.snakeCaseValidator = new snake_case_validator_1.SnakeCaseValidator();
    }
    validate(trackingObject) {
        if (typeof trackingObject.property === 'undefined')
            return;
        this.snakeCaseValidator.validate(trackingObject.property);
    }
}
exports.PropertyValidator = PropertyValidator;
