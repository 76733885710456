"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListPageType = void 0;
var ListPageType;
(function (ListPageType) {
    ListPageType["SEARCH"] = "search";
    ListPageType["STUDY"] = "study";
    ListPageType["ARTICLE"] = "article";
    ListPageType["STUDY_OPTIONS"] = "study_options";
    ListPageType["UNIVERSITY"] = "university";
    ListPageType["ACCOUNT"] = "account";
    ListPageType["COUNTRY"] = "country";
    ListPageType["COUNTRY_DEGREE"] = "country_degree";
    ListPageType["DISCIPLINE"] = "discipline";
    ListPageType["CITY"] = "city";
    ListPageType["UNIBUDDY"] = "unibuddy";
    ListPageType["HOME"] = "home";
})(ListPageType = exports.ListPageType || (exports.ListPageType = {}));
