"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnakeCaseValidator = void 0;
const error_message_1 = require("../../enums/error-message");
const validation_error_1 = require("../validation-error");
class SnakeCaseValidator {
    validate(messageToTest) {
        if (typeof messageToTest !== 'string' ||
            messageToTest !== messageToTest.toLowerCase() ||
            messageToTest.indexOf('-') !== -1) {
            throw new validation_error_1.ValidationError(error_message_1.ErrorMessage.INVALID_SNAKE_CASE);
        }
    }
}
exports.SnakeCaseValidator = SnakeCaseValidator;
