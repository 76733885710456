"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PascalCaseValidator = void 0;
const error_message_1 = require("../../enums/error-message");
const validation_error_1 = require("../validation-error");
class PascalCaseValidator {
    validate(messageToTest) {
        if (typeof messageToTest !== 'string' ||
            messageToTest.indexOf('-') !== -1 ||
            messageToTest.indexOf('_') !== -1 ||
            messageToTest.charAt(0).toUpperCase() !== messageToTest.charAt(0)) {
            throw new validation_error_1.ValidationError(error_message_1.ErrorMessage.INVALID_PASCAL_CASE);
        }
    }
}
exports.PascalCaseValidator = PascalCaseValidator;
