"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueValidator = void 0;
const error_message_1 = require("../../enums/error-message");
const validation_error_1 = require("../validation-error");
class ValueValidator {
    validate(trackingObject) {
        if (typeof trackingObject.value === 'undefined')
            return;
        if (typeof trackingObject.value === 'string' || isNaN(trackingObject.value)) {
            throw new validation_error_1.ValidationError(error_message_1.ErrorMessage.INVALID_NUMBER);
        }
    }
}
exports.ValueValidator = ValueValidator;
